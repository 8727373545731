<template>
  <section class="invoice-add-wrapper add-news-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer ref="rules">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card py-3"
            >
              <!-- Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="User"
                      rules="required"
                    >

                      <b-form-group
                        label="Users:"
                        label-for="email"
                      >

                        <multiselect
                          v-model="valueDestination"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search or add a tag"
                          label="name"
                          track-by="code"
                          :options="destination"
                          :multiple="true"
                          :taggable="true"
                          @tag="addDestinations"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tag"
                      rules="required"
                    >

                      <b-form-group
                        label="Tag:"
                        label-for="tag"
                      >

                        <multiselect
                          v-model="valueTag"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search or add a tag"
                          label="name"
                          track-by="code"
                          :options="tags"
                          :multiple="true"
                          :taggable="true"
                          @tag="addTags"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Exclude Users"
                    >
                      <b-form-group
                        label="Exclude Users (optional):"
                        label-for="exclude"
                      >
                        <multiselect
                          v-model="valueExcludeUsers"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search or add a tag"
                          label="name"
                          track-by="code"
                          :options="excludeUsers"
                          :multiple="true"
                          :taggable="true"
                          @tag="addExcludeUsers"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <b-form-group
                        label="Title:"
                        label-for="client"
                      >
                        <b-form-input
                          id="client"
                          v-model="title"
                          placeholder="Title"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="body"
                      rules="required"
                    >
                      <label for="textarea-default">Body:</label>
                      <quill-editor
                        v-if="user.userRole === 'Admin'"
                        v-model="content"
                        :options="snowOption"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Please enter a news content."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="submitHandler"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Publishing...
            </span>
            <span v-else>Publish</span>
          </b-button>
          <date-picker
            v-model="scheduleDate"
            format="DD-MM-YYYY | HH:mm"
            type="datetime"
            value-type="format"
            class="w-100"
            placeholder="Publish Date (optional)"
          />
          <div class="d-flex mt-1 justify-content-around">
            <span>Server Time:</span>
            <span>{{ serverDate }}</span>
          </div>
        </b-card>

        <b-card
          v-if="valueTag.some(e => e.code == 'Newsletter')"
          style="margin-top: -10px;"
        >
          <div
            class="d-flex badge badge-light-primary justify-content-center"
            style="margin: 0 auto; font-size: 15px; padding: 8px 0;"
          >Expire Notification Date</div>
          <date-picker
            v-model="expireNotificationDate"
            format="DD-MM-YYYY"
            type="date"
            value-type="format"
            class="w-100"
            style="margin-top: 5px;"
            placeholder="Expiration Date (optional)"
          />
        </b-card>

        <b-card
          v-if="content.length > 0 && title.length > 0"
          style="margin-top: -10px;"
        >
          <b-button
            v-b-modal.preview-modal
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            class="mb-75"
            block
            @click="openPreview"
          >Preview</b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-warning"
            class="mb-75"
            block
            :disabled="sending2"
            @click="sendTestEmail"
          >
            <span v-if="sending2">
              <b-spinner
                small
                type="grow"
              />
              Sending...
            </span>
            <span v-else>
              Send Test Email
            </span>
          </b-button>
          <b-form-input
            id="test-email"
            v-model="testEmail"
            type="email"
            placeholder="Test Preview Email"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-if="content.length > 0 && title.length > 0"
      id="preview-modal"
      ok-title="Close"
      size="xl"
      hide-footer
      title="Preview"
      container-class="modal-class"
    >
      <b-card
        class="mb-2"
      >
        <b-card-text class="px-5 py-2 mobile-card-text">
          <p class="float-right font-weight-bold">
            {{ formatMomentDate(new Date()) }}
            <b-badge
              class="ml-1 border rounded-pill text-white"
              size="sm"
              variant="success"
            >NEW</b-badge>
          </p>
          <h1 class="mb-4 pt-4 text-center">
            <span>{{ title }}</span>
          </h1>
          <div
            class="article-inner-body"
            v-html="content.replaceAll(`<img `, `<img style='max-width: 100%;' `)"
          />
        </b-card-text>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
/* eslint-disable */
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BBadge,
  BFormGroup,
  BFormInput,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import axios from '@/libs/axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    DatePicker,
    BFormInput,
    quillEditor,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      sending: false,
      title: '',
      content: '',
      destination: [
        { name: 'All', code: 'All' },
        { name: 'Subscribed (and above)', code: 'Subscribed' },
        { name: 'Trial (and above)', code: 'Trial' },
        { name: 'Pending (and above)', code: 'Pending' },
        { name: 'Registered (and above)', code: 'Registered' },
      ],
      valueDestination: [
        { name: 'All', code: 'All' },
      ],
      tags: [
        { name: 'Newsletter', code: 'Newsletter' },
        { name: 'Product Updates', code: 'Product Updates' },
      ],
      valueTag: [
        { name: 'Newsletter', code: 'Newsletter' },
      ],
      scheduleDate: null,
      serverDate: null,
      expireNotificationDate: null,
      excludeUsers: [
        { name: 'Subscribed (status)', code: 'Subscribed' },
        { name: 'Trial (status)', code: 'Trial' },
        { name: 'Pending (status)', code: 'Pending' },
        { name: 'Registered (status)', code: 'Registered' },
      ],
      valueExcludeUsers: [],
      testEmail: null,
      sending2: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    setInterval(() => {
      this.serverDate = new Date().toLocaleString('en-GB', { day: '2-digit', 'month': 'short', hour: '2-digit', 'minute': '2-digit', 'second': '2-digit', timeZone: 'Iceland' });
    }, 1000);
  },
  created() {
    if (this.user.isAdmin) {
      this.$store.dispatch('users/fetchUsersTag').then(tags => {
        tags.forEach(tag => {
          this.destination.push({ name: `${tag.tag_name} (tag)`, code: tag.tag_name })
          this.excludeUsers.push({ name: `${tag.tag_name} (tag)`, code: tag.tag_name })
        })
        this.$store.dispatch('users/fetchAllUsers').then(() => {
          this.allUsers = this.$store.state.users.allUsers
          this.allUsers.forEach(user => {
            this.destination.push({ name: user.email, code: user.uid })
          })
        })
      })
    }
  },
  methods: {
    sendTestEmail() {
      if (this.title.length == 0 || this.content.length == 0) {
        this.notify({
          text: 'Please fill in the title and content to send test email !',
          variant: 'danger',
        });
        return;
      };
      if (this.testEmail?.length == 0 || !this.testEmail?.includes('@')) {
        this.notify({
          text: 'Please enter a valid email !',
          variant: 'danger',
        });
        return;
      }

      const formData = new FormData();
      formData.append('title', this.title);
      formData.append('tag', this.valueTag.map(value => value.code).join(',') || 'Newsletter');
      formData.append('content', this.content);
      formData.append('email', this.testEmail);

      const headers = { 'Content-Type': 'multipart/form-data' };
      this.sending2 = true;
      
      axios.post('/api/news/preview-email', formData, { headers }).then(() => {
        this.notify({
          text: 'The test email has been successfully sent !',
          variant: 'success',
        });
        this.sending2 = false;
      }).catch(error => { 
        console.log(error) 
        this.sending2 = false;
      });
    },

    openPreview() {
      if (this.title.length == 0 || this.content.length == 0) {
        this.notify({
          text: 'Please fill in the title and content to preview !',
          variant: 'warning',
        });
        return;
      }
      this.$bvModal.show('preview-modal')
    },

    addExcludeUsers(value) {
      const tag = {
        name: value,
        code: value.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.excludeUsers.push(tag)
      this.valueExcludeUsers.push(tag)
    },

    addDestinations(value) {
      const tag = {
        name: value,
        code: value.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.destination.push(tag)
      this.valueDestination.push(tag)
    },

    addTags(value) {
      const tag = {
        name: value,
        code: value.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.tags.push(tag)
      this.valueTag.push(tag)
    },

    submitHandler() {
      const dateUnix = moment(this.serverDate, "DD MMM, HH:mm").valueOf();
      const scheduleUnix = moment(this.scheduleDate, "DD-MM-YYYY | HH:mm").valueOf();
      const expireUnix = moment(this.expireNotificationDate, "DD-MM-YYYY").valueOf();

      if (this.scheduleDate && scheduleUnix < dateUnix) {
        this.notify({
          text: 'Please enter a date from the future for Schedule Date !',
          variant: 'danger',
        });
        return;
      }
      if (this.expireNotificationDate && this.valueTag.some(e => e.code == 'Newsletter') && expireUnix < dateUnix) {
        this.notify({
          text: 'Please enter a date from the future for Expiration Date !',
          variant: 'danger',
        });
        return;
      }
      if (expireUnix <= scheduleUnix) {
        this.notify({
          text: 'Please enter a date before Schedule Date for Expiration Date !',
          variant: 'danger',
        });
        return;
      }
 
      this.$refs.rules.validate().then(success => {
        if (success) {
          const formData = new FormData()

          const uidsDestination = this.valueDestination.map(value => value.code)
          const uidsTag = this.valueTag.map(value => value.code)

          formData.append('title', this.title)
          formData.append('content', this.content)
          formData.append('destination', JSON.stringify(uidsDestination))
          formData.append('tag', uidsTag.join(','))
          if (this.scheduleDate != null && this.scheduleDate?.length > 0) {
            formData.append('date', moment(this.scheduleDate, "DD-MM-YYYY | HH:mm").format("YYYY-MM-DD HH:mm"));
          }
          if (this.expireNotificationDate && this.valueTag.some(e => e.code == 'Newsletter')) {
            formData.append('expire', moment(this.expireNotificationDate, "DD-MM-YYYY").format("YYYY-MM-DD"));
          }
          if (this.valueExcludeUsers.length > 0) {
            const uidsExcludeUsers = this.valueExcludeUsers.map(value => value.code)

            if(uidsDestination.some(e => uidsExcludeUsers.includes(e))) {
              this.notify({
                text: 'Some of the excluded users are also in the destination list !',
                variant: 'danger',
              });
              return;
            }

            formData.append('excluded', JSON.stringify(uidsExcludeUsers))
          }

          const headers = { 'Content-Type': 'multipart/form-data' };
          this.sending = true

          axios.post('/api/news/store', formData, { headers }).catch(error => { console.log(error) })

          this.notify({
            text: 'The newsletter has been successfully published !',
            variant: 'info',
          });

          setTimeout(() => {
            this.$router.push('/news/list');
            this.sending = false;
          }, 5000);
        }
      })
    },

    formatMomentDate(date) {
      return moment(date).format('MMMM DD, YYYY | HH:mm');
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.add-news-wrapper .ql-editor {
  min-height: 320px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
